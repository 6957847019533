import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import { useRecoilState } from "recoil";
import useLocalStorage from "react-use-localstorage";

import Loader from "../../components/Layout/Loader";

import { post } from "../../lib/Api/Api";
import { userState } from "../../lib/store/user";

const AuthConfirm = () => {
  const [, setUser] = useRecoilState(userState);
  const [, setToken] = useLocalStorage("focus-time-token");

  useEffect(() => {
    (async () => {
      try {
        const code = window.location.search
          .replace("?", "")
          .split("&")
          .find((c) => c.includes("code"))
          .split("=")[1];
        const { token, profile } = await post("auth", {
          code,
        });
        setToken(token);
        setUser({ ...profile, token });

        if (window.gtag) {
          window.gtag("event", "login", { method: "Google" });
        }

        return navigate("/settings", { replace: true });
      } catch (e) {
        console.log(e);
        return navigate("/", { replace: true });
      }
    })();
  }, []);

  return (
    <div className="absolute z-50 pt-40 inset-0 bg-white">
      <Loader />
    </div>
  );
};

export default AuthConfirm;
