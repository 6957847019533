import React  from "react";
import BlurInput from "./BlurInput";

const TimeInput = ({ value, onChange }) => {
  return (
    <BlurInput
      type="time"
      value={value}
      onChange={onChange}
      className="focus:ring-indigo-500 focus:border-indigo-500 text-sm pt-3 pb-1 block px-6 rounded-md shadow-sm border-gray-300"
    />
  );
};

export default TimeInput;
