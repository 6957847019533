import React from "react";

const CalendarGraphic = ({}) => {
  return (
    <div className="flex text-xs md:text-sm font-semibold gap-3 lg:mr-36 mb-16 md:mb-0 relative h-64 md:h-auto">
      <div className="flex-1 w-1/4">
        <div className="h-24 p-1 px-2 mb-2 rounded-lg overflow-hidden opacity-40 text-green-600 bg-white">
          Monday team <br />
          kick-off
        </div>
        <div className="h-32 p-1 px-2 mb-2 rounded-lg bg-white">
          Focus time 🙉
        </div>
        <div className="h-8 p-1 px-2 mb-2 rounded-lg opacity-40 text-green-600 bg-white">
          Client list sync
        </div>
        <div className="h-16 p-1 px-2 mb-2 rounded-lg opacity-40 text-green-600 bg-white">
          Lunch meeting
        </div>
        <div className="h-8 p-1 px-2 mb-2 rounded-lg overflow-hidden whitespace-nowrap opacity-40 text-green-600 bg-white">
          Project proposal
        </div>
      </div>
      <div className="flex-1 w-1/4">
        <div className="h-16 p-1 px-2 mb-2 rounded-lg opacity-40 text-green-600 bg-white">
          Breakfast with champions
        </div>
        <div className="h-32 p-1 px-2 mb-2 rounded-lg bg-white">
          Focus time 🙉
        </div>
        <div className="h-24 p-1 px-2 mb-2 rounded-lg opacity-40 text-green-600 bg-white">
          Meeting client X
        </div>
        <div className="h-8 p-1 px-2 mb-2 overflow-hidden whitespace-nowrap rounded-lg opacity-40 text-green-600 bg-white">
          One-to-one
        </div>
        <div className="h-8 p-1 px-2 mb-2 overflow-hidden whitespace-nowrap rounded-lg opacity-40 text-green-600 bg-white">
          Roadmap review
        </div>
      </div>
      <div className="flex-1 w-1/4">
        <div className="h-24 p-1 px-2 mb-2 rounded-lg opacity-40 text-green-600 bg-white">
          Mid-week priorities
        </div>
        <div className="h-8 p-1 px-2 mb-2 rounded-lg opacity-40 text-green-600 bg-white">
          Meeting client Y
        </div>
        <div className="h-16 p-1 px-2 mb-2 rounded-lg opacity-40 text-green-600 bg-white">
          Week review
        </div>
        <div className="h-32 p-1 px-2 mb-2 rounded-lg bg-white">
          Focus time 🙉
        </div>
        <div className="h-8 p-1 px-2 mb-2 overflow-hidden whitespace-nowrap rounded-lg opacity-40 text-green-600 bg-white">
          Login discussion
        </div>
      </div>
      <div className="bg-gradient-to-t from-green-500 absolute inset-0 mt-40 h-64" />
    </div>
  );
};

export default CalendarGraphic;
