import React from "react";
import ReactDOM from "react-dom";
import RecoilOutside from "recoil-outside";
import { RecoilRoot } from "recoil";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./index.css";
import App from "./App";

Sentry.init({
  dsn: "https://7b801db15a61416193e8e5888311c8c1@o290613.ingest.sentry.io/5904305",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <RecoilRoot>
    <RecoilOutside />
    <App />
  </RecoilRoot>,
  document.getElementById("root")
);
