import React from "react";

import Pricing from "../../components/Marketing/Pricing";
import CalendarGraphic from "../../components/Marketing/CalendarGraphic";

import { signInWithGoogle } from "../../lib/Auth/Auth";

import { ReactComponent as CalendarIcon } from "../../assets/Integrations/calendar.svg";

const Home = () => {
  return (
    <div className={"font-marketing bg-green-500"}>
      {/* --- Hero --- */}
      <div className="mx-auto max-w-6xl px-6 pb-8 md:pb-32 pt-10 md:pt-24 overflow-hidden">
        <div className="lg:flex items-center justify-between">
          <CalendarGraphic />
          <div className="text-center flex-1 lg:text-left pb-4 relative z-20">
            <h1
              className="mt-1 text-3xl font-extrabold tracking-tight sm:text-5xl text-white"
              style={{ lineHeight: 1.1 }}
            >
              Keep time for deep work, even as your calendar fills up.
            </h1>
            <p className="mt-3 opacity-70 text-white prose-lg sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Focus Time automatically schedules time for deep work, around your
              other meetings.
            </p>
            <button
              className="bg-white text-green-600 shadow-lg font-semibold px-8 py-4 mt-16 rounded-full inline-flex items-center"
              onClick={signInWithGoogle}
            >
              <CalendarIcon className="h-6 w-6 mr-4" />
              Add to Google Calendar &rarr;
            </button>
          </div>
        </div>
      </div>

      {/* --- Features --- */}
      <a name="features"> </a>
      <div className="bg-white py-8 lg:py-20">
        <div className="mx-auto lg:flex max-w-6xl">
          <div className="lg:w-1/2 px-6 lg:py-12">
            <h2 className="text-3xl font-bold mb-4 leading-relaxed">
              <span role="img" aria-label="automagically">
                ✨
              </span>
              <br />
              Automagically fills in the gaps
            </h2>
            <p className="prose-lg">
              Focus Time schedules focus time blocks for you around the meetings
              in your calendar, and automatically updates within a few seconds
              when new meetings get added.
              <br />
              <br />
              No more manually shifting things around.
            </p>
          </div>

          <div className="lg:w-1/2 px-6 lg:py-12 mt-16 lg:mt-0">
            <h2 className="text-3xl font-bold mb-4 leading-relaxed">
              <span role="img" aria-label="checkmark">
                ✅
              </span>
              <br />
              Lets people know where you're at
            </h2>
            <p className="prose-lg">
              We'll mark your focus time as free time for other people to book
              meetings over as long as there's multiple moments in the day
              available for focus time.
            </p>
          </div>
        </div>
      </div>

      {/* --- Pricing --- */}
      <a name="pricing"> </a>
      <Pricing />
    </div>
  );
};

export default Home;
