import React, { useEffect, useState } from "react";

const BlurInput = ({ value, onChange, as = "input", ...props }) => {
  const [newValue, setNewValue] = useState(value);
  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return React.createElement(as, {
    ...props,
    value: newValue,
    onChange: ({ target: { value } }) => setNewValue(value),
    onBlur: () => (value !== newValue ? onChange(newValue) : null),
  });
};

export default BlurInput;
