import React from "react";

import Field from "../../components/Forms/Field";
import Title from "../../components/Text/Title";
import SettingsMenu from "../../components/Layout/SettingsMenu";
import TimeInput from "../../components/Forms/TimeInput";

const parseDuration = (value) => {
  if (value.includes(":")) {
    const [hours, mins] = value.split(":");
    return hours * 3600 + mins * 60;
  }
  const numeric = Number(value);
  if (numeric && !isNaN(numeric)) {
    return numeric * 3600;
  }

  return false;
};

const formatDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const mins = (duration - hours * 3600) / 60;

  return `${hours < 10 ? "0" : ""}${hours}:${mins < 10 ? "0" : ""}${mins}`;
};

const Rules = () => {
  return (
    <div className="sm:flex">
      <SettingsMenu />
      <div className="w-full">
        <Title>Scheduling rules</Title>
        <p className="prose -mt-4 mb-10">
          Set the scheduling rules to get your focus blocks just right.
        </p>

        <Field
          title="Focus time block duration"
          description="We'll try to always maximize the length of your Focus Time block."
        >
          {({ preferences, setPreferences }) => (
            <div className="flex items-center gap-4">
              <span className="text-gray-500">At least</span>
              <TimeInput
                value={formatDuration(preferences.minDuration)}
                onChange={(value) => {
                  value = parseDuration(value);
                  if (value && value > 3600 * 12) {
                    value = value - 3600 * 12;
                  }
                  value && setPreferences({ minDuration: value });
                }}
              />
              <span className="text-gray-500">and at most</span>
              <TimeInput
                value={formatDuration(preferences.maxDuration)}
                onChange={(value) => {
                  value = parseDuration(value);
                  if (value && value > 3600 * 12) {
                    value = value - 3600 * 12;
                  }
                  value && setPreferences({ maxDuration: value });
                }}
              />
              <span className="text-gray-500">hours</span>
            </div>
          )}
        </Field>
        <Field
          title="Preferred time of day"
          description="We'll try to always schedule the largest amount of time available, but if there are multiple long time slots available still, this will inform us how to prioritize."
        >
          {({ preferences, setPreferences }) => (
            <select
              value={preferences.preferEarlier ? "true" : "false"}
              onChange={({ target: { value } }) =>
                setPreferences({ preferEarlier: value === "true" })
              }
              className="w-full focus:ring-indigo-500 focus:border-indigo-500 text-sm p-3 px-4 block rounded-md shadow-sm border-gray-300"
            >
              <option value="true">Prefer earlier in the day</option>
              <option value="false">Prefer later in the day</option>
              ))}
            </select>
          )}
        </Field>
        <Field
          title="Preferred time slot"
          description="If it is available, we will always pick this time slot."
        >
          {({ preferences, setPreferences }) => (
            <TimeInput
              value={preferences.defaultTimeslot}
              onChange={(value) => {
                setPreferences({ defaultTimeslot: value });
              }}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

export default Rules;
