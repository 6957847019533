import React, { useEffect, Suspense } from "react";
import { Location } from "@reach/router";
import { useRecoilState } from "recoil";
import useLocalStorage from "react-use-localstorage";
import useSWR from "swr";

import Routes from "./routes/Routes";
import Navigation from "./components/Layout/Navigation";
import Footer from "./components/Marketing/Footer";
import Loader from "./components/Layout/Loader";

import { userState } from "./lib/store/user";
import { get } from "./lib/Api/Api";

const App = () => {
  const [user, setUser] = useRecoilState(userState);
  const [token, setToken] = useLocalStorage("focus-time-token");
  const { data, error } = useSWR(token ? "me" : undefined, get);

  useEffect(() => {
    if (data) {
      setUser({...data, token});
    }
    if (error) {
      setToken("");
      setUser(null);
    }
  }, [data, error]);

  useEffect(() => {
    // Redirect www. -> plain domain
    if (window.location.hostname.includes("www.")) {
      window.location.href = window.location.href.replace("www.", "");
    }
  }, []);

  return (
    <div className="min-h-screen font-marketing bg-white select-none">
      <Navigation
        signOut={() => {
          setToken("");
          setUser(null);
        }}
      />
      <div className={user ? `max-w-6xl px-6 py-12 mx-auto` : ""}>
        <Location>
          {({ location }) => (
            <Suspense fallback={<Loader />}>
              <Routes user={user} location={location} />
            </Suspense>
          )}
        </Location>
      </div>
      {!user && <Footer />}
    </div>
  );
};

export default App;
