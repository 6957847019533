import React, { useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDown, Loader, User } from "react-feather";

import withUser from "../../hooks/withUser";
import { post } from "../../lib/Api/Api";

const UserMenu = ({ signOut }) => {
  const [loading, setLoading] = useState(false);

  const user = withUser();
  if (!user.email) {
    return null;
  }

  const image = user.picture || user.imageUrl;

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center">
            <div
              className="bg-gray-300 flex items-center justify-center text-white border bg-center bg-cover font-medium w-8 h-8 text-sm rounded-full focus:outline-none"
              style={{ backgroundImage: `url(${image || ""})` }}
            >
              {!image && <User className="w-5" />}
            </div>
            <ChevronDown className="ml-1.5 w-5 text-gray-400" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-48 mt-2 absolute py-3 mt-2 text-sm bg-white shadow-xl border rounded-lg focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={async () => {
                    await signOut();
                    location.href = "/";
                  }}
                  className={`block w-full text-left cursor-pointer px-4 py-2 ${
                    active ? "bg-gray-800 text-white" : "text-gray-900"
                  }`}
                >
                  Sign out
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default UserMenu;
