import React from "react";
import useSWR from "swr";

import Field from "../../components/Forms/Field";
import Title from "../../components/Text/Title";
import SettingsMenu from "../../components/Layout/SettingsMenu";

import { get } from "../../lib/Api/Api";
import BlurInput from "../../components/Forms/BlurInput";

const Appearance = () => {
  const { data: colors } = useSWR("calendar/colors", get);

  return (
    <div className="sm:flex">
      <SettingsMenu />
      <div className="w-full">
        <Title>Appearance</Title>
        <p className="prose -mt-4 mb-10">
          Determine how your focus time blocks will appear in your calendar.
        </p>

        <div className="lg:flex lg:gap-16">
          <div className="flex-1">
            <Field title="Event title">
              {({ preferences, setPreferences }) => (
                <BlurInput
                  type="text"
                  value={preferences.eventTitle}
                  onChange={(value) => setPreferences({ eventTitle: value })}
                  className="focus:ring-indigo-500 focus:border-indigo-500 text-sm h-12 block px-4 w-full rounded-md shadow-sm border-gray-300"
                />
              )}
            </Field>
          </div>
          <div>
            <Field title="Color">
              {({ preferences, setPreferences }) => (
                <div className="flex">
                  {colors &&
                    colors.slice(0, 8).map(([id, col]) => (
                      <button
                        onClick={() =>
                          setPreferences({ eventColorId: Number(id) })
                        }
                        className={`${
                          preferences.eventColorId === Number(id)
                            ? "border border-gray-300 shadow-sm"
                            : ""
                        } p-3 rounded-full bg-white`}
                      >
                        <span
                          className="h-6 w-6 rounded-full block"
                          style={{
                            backgroundColor: col,
                            filter: "brightness(0.75) contrast(1.8)",
                          }}
                        />
                      </button>
                    ))}
                </div>
              )}
            </Field>
          </div>
        </div>
        <Field title="Event description">
          {({ preferences, setPreferences }) => (
            <BlurInput
              as="textarea"
              value={preferences.eventDescription}
              onChange={(value) => setPreferences({ eventDescription: value })}
              className="focus:ring-indigo-500 focus:border-indigo-500 text-sm h-48 block p-4 w-full rounded-md shadow-sm border-gray-300"
            />
          )}
        </Field>
      </div>
    </div>
  );
};

export default Appearance;
