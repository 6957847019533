import React from "react";

import Field from "../../components/Forms/Field";
import Title from "../../components/Text/Title";
import SettingsMenu from "../../components/Layout/SettingsMenu";
import usePreferences from "../../hooks/usePreferences";
import { days, timezones } from "../../lib/timezones";
import useSWR from "swr";
import { get } from "../../lib/Api/Api";
import { signInWithGoogle } from "../../lib/Auth/Auth";
import withUser from "../../hooks/withUser";
import TimeInput from "../../components/Forms/TimeInput";

const General = () => {
  const [prefs] = usePreferences();
  const user = withUser();
  const { error } = useSWR("calendar/colors", get);

  return (
    <div className="sm:flex">
      <SettingsMenu />
      <div className="w-full">
        <Title>Your focus blocks</Title>
        <p className="prose -mt-4 mb-10">
          Select when and how we should add focus time blocks.
        </p>

        {!prefs.days.length && !error && (
          <div className="bg-gray-100 p-12 md:px-24 rounded-lg mb-10 text-center">
            <h3 className="font-semibold text-xl leading-relaxed mb-3 tracking-relaxed">
              🐒 Ready to get started?
            </h3>
            <p>
              Select one or more days below, and we'll automatically start
              scheduling your focus time. Tick some boxes, update some settings,
              and check your Google Calendar to see the results.
            </p>
          </div>
        )}

        {error && (
          <div className="bg-yellow-100 px-8 py-7 rounded-lg mb-10">
            <h3 className="font-semibold text-sm leading-relaxed mb-2 tracking-relaxed">
              ⚠️️ Seems like we're missing some permissions
            </h3>
            <p className="mb-6 text-sm">
              We need access to your calendar in order to schedule Focus Time
              blocks. We won't touch any of your other calendar events.
            </p>
            <button
              className="font-bold text-sm text-indigo-500"
              onClick={() => signInWithGoogle(user.id)}
            >
              Give calendar access &rarr;
            </button>
          </div>
        )}

        <div className={error ? "pointer-events-none opacity-50" : ""}>
          <Field title="Focus time days">
            {({ preferences, setPreferences }) => (
              <div className="grid gap-4 grid-cols-5">
                {days.map((day) => (
                  <label
                    key={day}
                    htmlFor={day}
                    id={`${day}-label`}
                    className={`block border ${
                      preferences.days.includes(day)
                        ? `border-indigo-400 bg-indigo-100 text-indigo-600 shadow-sm`
                        : "border-gray-200"
                    } cursor-pointer font-semibold w-full text-sm rounded-lg px-4 pt-5 pb-4 flex flex-col text-center focus:ring-indigo-500 focus:border-indigo-500`}
                  >
                    <input
                      id={day}
                      aria-describedby={`${day}-label`}
                      name={day}
                      type="checkbox"
                      onChange={() =>
                        preferences.days.includes(day)
                          ? setPreferences({
                              days: preferences.days.filter((d) => d !== day),
                            })
                          : setPreferences({ days: [...preferences.days, day] })
                      }
                      checked={preferences.days.includes(day)}
                      className="focus:ring-indigo-500 h-4 w-4 cursor-pointer mx-auto mb-2 text-indigo-600 border-gray-300 inline-block rounded"
                    />
                    <p>{day}</p>
                  </label>
                ))}
              </div>
            )}
          </Field>

          <div className="lg:grid lg:grid-cols-5 lg:gap-4">
            <div className="col-span-2">
              <Field title="Schedule focus time between">
                {({ preferences, setPreferences }) => (
                  <div className="flex items-center">
                    <TimeInput
                      value={preferences.startOffset}
                      onChange={(value) =>
                        setPreferences({ startOffset: value })
                      }
                    />
                    <span className="px-4 text-gray-500">and</span>
                    <TimeInput
                      value={preferences.endOffset}
                      onChange={(value) => setPreferences({ endOffset: value })}
                    />
                  </div>
                )}
              </Field>
            </div>
            <div className="col-span-3">
              <Field title="Time zone">
                {({ preferences, setPreferences }) => (
                  <select
                    value={preferences.timezone}
                    onChange={({ target: { value } }) =>
                      setPreferences({ timezone: value })
                    }
                    className="w-full focus:ring-indigo-500 focus:border-indigo-500 text-sm p-3 px-4 block rounded-md shadow-sm border-gray-300"
                  >
                    {timezones.sort().map((timezone) => (
                      <option key={timezone} value={timezone}>
                        {timezone}
                      </option>
                    ))}
                  </select>
                )}
              </Field>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
