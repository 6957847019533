import React, { Fragment } from "react";
import { Link } from "@reach/router";
import { Popover, Transition } from "@headlessui/react";
import { Menu, X } from "react-feather";

import MiniButton from "../Forms/MiniButton";

import { signInWithGoogle } from "../../lib/Auth/Auth";

import { ReactComponent as Icon } from "../../assets/Branding/Icon.svg";

const navigation = [
  { name: "Features", href: "/#features" },
  { name: "Pricing", href: "/#pricing" },
  { name: "Support", href: "/support" },
];

const MarketingNavigation = () => {
  return (
    <div className="relative bg-green-500 text-white font-marketing">
      <div className="relative py-6">
        <Popover>
          {({ open }) => (
            <>
              <nav
                className="relative max-w-6xl mx-auto flex items-center justify-between px-6"
                aria-label="Global"
              >
                <div className="flex items-center flex-1">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <Link to="/" className="flex items-center">
                      <Icon className="h-8 w-auto sm:h-10 mr-4" />
                      <span className="text-xl text-white font-bold">Focus Time</span>
                    </Link>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-white-400 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                        <span className="sr-only">Open main menu</span>
                        <Menu className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="hidden md:block md:ml-10 md:space-x-10">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        target={item.href.includes("//") ? "_blank" : undefined}
                        className="font-medium text-green-200 hover:text-white"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
                <MiniButton className="hidden md:block" onClick={signInWithGoogle}>
                  Sign in &rarr;
                </MiniButton>
              </nav>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute top-0 inset-x-0 p-2 z-50 transition transform origin-top-right md:hidden"
                >
                  <div className="rounded-lg shadow-md bg-white text-black ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-5 pt-4 flex items-center justify-between">
                      <Link to="/" className="flex items-center">
                        <Icon className="h-8 w-auto sm:h-10" />
                        <span className="text-xl ml-4 font-bold">Focus Time</span>
                      </Link>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                          <span className="sr-only">Close main menu</span>
                          <X className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="px-2 pt-2 pb-3 space-y-1">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <a
                      className="block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100"
                      onClick={signInWithGoogle}>
                      Sign in &rarr;
                    </a>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
};

export default MarketingNavigation;
