import React from "react";
import { Redirect, Router } from "@reach/router";

import Home from "./Public/Home";
import Privacy from "./Public/Privacy";
import Support from "./Public/Support";

import General from "./Settings/General";
import Appearance from "./Settings/Appearance";
import Rules from "./Settings/Rules";

import Auth from "./Public/Auth";

const DefaultRedirect = () => <Redirect to="/settings" />;

const Routes = ({ user, location }) => (
  <Router location={location}>
    {user ? (
      <>
        <General path="/settings" />
        <Appearance path="/appearance" />
        <Rules path="/rules" />
        <DefaultRedirect path="/" default />
      </>
    ) : (
      <>
        <Home path="/" default />
      </>
    )}

    <Support path="/support" />
    <Privacy path="/privacy" />
    <Auth path="/auth" />
  </Router>
);

export default Routes;
