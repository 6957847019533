import axios from "axios";
import { promiseGetRecoil } from "recoil-outside";

import { userState } from "../store/user";

const api = axios.create({
  baseURL: "/api/",
});

api.interceptors.request.use(async (config) => {
  const user = await promiseGetRecoil(userState);
  const token = user ? user.token : localStorage["focus-time-token"];
  config.headers.Authorization = token ? `Bearer ${token}` : undefined;
  return config;
});

export const get = async (...args) => (await api.get(...args)).data;
export const post = async (...args) => (await api.post(...args)).data;
