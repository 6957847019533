import React from "react";
import { Link } from "@reach/router";

import MarketingNavigation from "../Marketing/MarketingNavigation";
import withUser from "../../hooks/withUser";

import { ReactComponent as Icon } from "../../assets/Branding/Icon.svg";
import UserMenu from "./UserMenu";

const Navigation = ({ signIn, signOut, loading }) => {
  const user = withUser();

  if (!user) {
    return <MarketingNavigation loading={loading} signIn={signIn} />;
  }

  return (
    <div className="relative pt-6 pb-12 sm:pb-16">
      <nav
        className="relative max-w-6xl mx-auto flex items-center justify-between px-6"
        aria-label="Global"
      >
        <div className="flex items-center flex-1">
          <div className="flex items-center justify-between w-full md:w-auto">
            <Link to="/settings" className="flex items-center">
              <Icon className="h-8 w-auto text-green-500 sm:h-10 mr-4" />
              <span className="text-xl text-gray-900 font-bold">
                Focus Time
              </span>
            </Link>
          </div>
        </div>
        <UserMenu signOut={signOut} />
      </nav>
    </div>
  );
};

export default Navigation;
