import React from "react";
import { Clock, Code, Feather, LifeBuoy, Sliders } from "react-feather";
import { Link } from "@reach/router";
import { UpdateNotification } from 'react-update-popup'
import 'react-update-popup/dist/index.css'

const menuItems = [
  {
    to: "/settings",
    title: "Basic setup",
    icon: Clock,
  },
  {
    to: "/appearance",
    title: "Appearance",
    icon: Feather,
  },
  {
    to: "/rules",
    title: "Scheduling rules",
    icon: Sliders,
  },
  {
    to: "/support",
    title: "Support",
    icon: LifeBuoy,
  },
];

const SettingsMenu = ({}) => {
  return (
    <div className="flex-0 w-64 mr-24 mb-10">
      {menuItems.map(({ to, title, icon: Icon }, index) => (
        <Link
          key={to}
          to={to}
          getProps={({ isCurrent, isPartiallyCurrent }) => ({
            className: `flex items-center font-medium ${
              isCurrent || (index && isPartiallyCurrent)
                ? "text-gray-900"
                : "text-gray-400"
            } transition-all hover:text-gray-600 py-3`,
          })}
        >
          <Icon size={20} className="mr-3 flex-0" />
          {title}
        </Link>
      ))}
      <UpdateNotification />
    </div>
  );
};

export default SettingsMenu;
