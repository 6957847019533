import React from "react";

const pricing = {
  tiers: [
    {
      title: "Starter",
      price: "Free",
      description:
        "Automated focus time scheduling on weekdays.",
    },
    {
      title: "Advanced",
      price: "$5/mo",
      description:
        "Update event titles, colors and more detailed scheduling rules.",
    },
  ],
};

const Pricing = ({}) => {
  return (
    <div className="bg-gray-100 -mb-10">
    <div className="mx-auto max-w-6xl px-6 py-32 text-center">
      <h2 className="text-3xl font-bold mb-4 sm:leading-none sm:tracking-tight">
        Super simple pricing
      </h2>
      <p className="max-w-xl prose prose-lg mx-auto">
        The basic features are free, advanced settings are available for just $5/month.
      </p>

      {/* Tiers */}
      <div className="mt-16 space-y-12 lg:space-y-0 max-w-2xl mx-auto lg:grid lg:grid-cols-2 lg:gap-x-8">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative p-8 bg-white border border-gray-200 rounded-2xl text-center"
          >
            <h3 className="text-xl font-semibold text-gray-900">
              {tier.title}
            </h3>
            <p className="mt-4 items-baseline text-5xl font-extrabold tracking-tight text-gray-900">
              {tier.price}
            </p>
            <p className="mt-6 text-gray-500">{tier.description}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Pricing;
