export const signInWithGoogle = (loginHint) => {
  window.location.href =
    `https://accounts.google.com/o/oauth2/v2/auth` +
    `?client_id=427408093934-cn2u6lghq1fckc7upkkuead4sohvbadj.apps.googleusercontent.com` +
    `&redirect_uri=${encodeURIComponent(`${window.location.origin}/auth`)}` +
    `&response_type=code` +
    `&scope=${encodeURIComponent(
      "profile email https://www.googleapis.com/auth/calendar.events.owned"
    )}` +
    `&access_type=offline`
    + (loginHint ? `&login_hint=${encodeURIComponent(loginHint)}&prompt=consent` : '');
};
