import React, { useState } from "react";
import { Check, Loader } from "react-feather";

import usePreferences from "../../hooks/usePreferences";

const fieldTimeouts = {};

const Field = ({ title, description, htmlFor, children }) => {
  const [preferences, setPrefs] = usePreferences();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const setPreferences = async (value) => {
    setLoading(true);
    await setPrefs(value);
    setLoading(false);
    setSaved(true);
    if (fieldTimeouts[title]) {
      clearTimeout(fieldTimeouts[title]);
    }
    fieldTimeouts[title] = setTimeout(() => setSaved(false), 3000);
  };

  return (
    <div className="sm:col-span-4 mb-16">
      <label
        htmlFor={htmlFor}
        className="block sm:mb-2 font-medium flex items-center"
      >
        <span>{title}</span>
        <span className="ml-2">
          {loading && <Loader className="animate-spin text-gray-500 w-4 h-4" />}
          {saved && !loading && <Check className="text-green-500 w-4 h-4" />}
        </span>
      </label>
      {description && (
        <p className="text-sm text-gray-500 mt-1 mb-3">{description}</p>
      )}
      <div className="mt-1">{children({ preferences, setPreferences })}</div>
    </div>
  );
};

export default Field;
