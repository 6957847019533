import React from "react";
import { Loader as LoaderEl } from "react-feather";

const Loader = ({}) => {
  return (
    <div className="p-48 w-full flex items-center justify-center text-gray-500">
      <div className="w-10 h-10">
        <LoaderEl className="animate-spin w-10 h-10" />
      </div>
    </div>
  );
};

export default Loader;
