import React from "react";
import { useRecoilState } from "recoil";

import { userState } from "../lib/store/user";

const withUser = () => {
  const [user] = useRecoilState(userState);

  return user;
};

export default withUser;
