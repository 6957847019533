import React from "react";
import { GitHub, Globe } from "react-feather";
import { Link } from "@reach/router";

const Footer = ({}) => {
  return (
    <footer className="bg-white border-t border-gray-100 mt-10">
      <div className="max-w-6xl px-6 mx-auto py-10 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          <a
            href="https://schof.co/?utm_source=focus-time.app"
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Website</span>
            <Globe className="h-5 w-5" aria-hidden="true" />
          </a>
          <a
            href="https://github.com/tschoffelen"
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">GitHub</span>
            <GitHub className="h-5 w-5" aria-hidden="true" />
          </a>
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-sm text-gray-400">
            A project by{" "}
            <a
              className="font-bold hover:text-gray-500 ml-0.5"
              href="https://schof.co/?utm_source=focus-time.app"
            >
              ■ Schof
            </a>
            <Link
              to="/privacy"
              className="block sm:inline-block mt-4 sm:mt-0 sm:ml-10 hover:text-gray-500"
            >
              Privacy
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
