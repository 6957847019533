import React from "react";
import useSWR from "swr";

import { get, post } from "../lib/Api/Api";

const usePreferences = (swrOptions = {}) => {
  const { data, mutate } = useSWR("preferences", get, {
    suspense: true,
    ...swrOptions,
  });

  const updatePreferences = async (newPreferences) => {
    await mutate({ ...data, ...newPreferences }, false);
    await post("preferences", newPreferences);
    return mutate();
  };

  return [data, updatePreferences];
};

export default usePreferences;
