import React from "react";
import { ChevronDown, Mail } from "react-feather";
import { Disclosure } from "@headlessui/react";

import Title from "../../components/Text/Title";
import SettingsMenu from "../../components/Layout/SettingsMenu";
import withUser from "../../hooks/withUser";

const faqs = [
  {
    question: "What does Focus Time do?",
    answer:
      <>Focus Time automatically creates blocks of time for <a href="https://blog.usejournal.com/creating-focus-time-168bfa42b7f7" target="_blank" rel="noopener noreferrer">deep work</a> without any interruptions.</>,
  },
  {
    question: "How is that different than me creating recurring tasks in my calendar?",
    answer: <>This app automatically schedules blocks of Focus Time around your meetings, and updates in real time as new meetings are put into your calendar.</>
  },
  {
    question: "How frequently does this update?",
    answer: <>Updates happen at least once a day, but are also triggered by changes to the events in your primary calendar.</>
  },
  {
    question: "Why does my Focus Time block overlap with other events?",
    answer: <>Focus Time only tries to schedule around events that have been <strong>confirmed</strong> (i.e. that you responded 'Yes' to) and that are marked as <strong>busy</strong> rather than free.</>
  },
  {
    question: "What happens if I manually move a Focus Time block?",
    answer: <>We'll try to keep your manually selected preference for as long as that time slot stays available. If another meeting is booked during the timeslot, we might move the Focus Time block.</>
  },
  {
    question: "How can I disable the app?",
    answer: <>Un-check all the days on the general settings tab. This will remove all future scheduled Focus Time blocks and prevent any new ones from being added.</>
  }
];

const Support = () => {
  const user = withUser();

  return (
    <div className={user ? "sm:flex" : "mx-auto max-w-6xl px-6 py-32"}>
      {user && <SettingsMenu />}
      <div className="w-full">
        <Title>Support</Title>
        <p className="prose -mt-4 mb-10">Help us to help you.</p>

        <h3 className="text-xl pt-4 font-bold text-gray-900">
          Send us a message
        </h3>
        <p className="prose mb-3 mt-4">
          If you have a specific question or request, please let us know and
          we're more than happy to help you get the most out of this tool.
        </p>
        <a
          href="mailto:support@focus-time.app"
          className="flex items-center mb-16 gap-3 text-indigo-500"
        >
          <Mail size={20} />
          support@focus-time.app
        </a>

        <h3 className="text-xl font-bold text-gray-900">
          Frequently asked questions
        </h3>
        <dl className="space-y-6 divide-y divide-gray-200 mb-16">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">
                        {faq.question}
                      </span>
                      <span className="ml-6 h-6 flex items-center">
                        <ChevronDown
                          className={`${
                            open ? "-rotate-180" : "rotate-0"
                          } h-6 w-6 transform`}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="prose text-gray-500">{faq.answer}</p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Support;
